<template>
  <div id="StuPersonalAwardForm">
    <!-- 表单 -->
    <van-form ref="form">
      <van-field v-model="form.stuName" placeholder="请填写姓名" :disabled="true" label="姓名" />
      <van-field v-model="form.stuNo" placeholder="请填写学号" :disabled="true" label="学号" />
      <van-field v-model="form.awardName" placeholder="请填写获奖名称" required :rules="[{ required: true }]" label="获奖名称" />
      <custom-date-picker required ref="awardAt" v-model="form.awardAt" label="获奖日期" placeholder="点击选择获奖日期" />
      <van-field v-model="form.awardDept" placeholder="请填写颁发单位" required :rules="[{ required: true }]" label="颁发单位" />
      <van-field v-model="form.remark" rows="3" class="textarea-style" label="说明" type="textarea" maxlength="300" placeholder="请输入说明" show-word-limit />
    </van-form>
    <van-cell-group title="证明附件" class="upload-file">
      <file-upload v-model="form.fileId" miz="STU_AWARD_PER" biz="storage" :multiple="3" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
        <span slot="tip" class="upload-tips">
          支持上传任意文件,文件最大1M
        </span>
      </file-upload>
    </van-cell-group>
    <div class="submit-button shadow-style">
      <van-button round block type="primary" :loading="loading" native-type="submit" @click="doSubmit">保 存</van-button>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validCustom } from "@/utils";
import { add, edit } from "@/api/modules/student/personalAward";
import fileUpload from "@/components/FileUpload";

export default {
  name: "StuPersonalAwardForm",
  components: { fileUpload },
  data() {
    return {
      title: "新增",
      loading: false,
      customRuleFileds: ["awardAt"],
      form: {
        id: null,
        stuNo: null,
        stuName: null,
        awardName: null,
        awardAt: null,
        remark: null,
        awardDept: null,
        fileId: [],
      },
    };
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.form.stuNo = this.user.username;
      this.form.stuName = this.user.nickName;
      if (this.$route.query && this.$route.query.id) {
        let data = this.$route.query;
        document.title = "编辑学生获奖信息";
        this.form.fileId = data.fileId;
        this.$nextTick((e) => {
          this.form = data;
          this.form.awardAt = data.awardAt;
        });
      } else {
        document.title = "新增学生获奖信息";
      }
    },
    onClickLeft() {
      this.form = {
        id: null,
        stuNo: null,
        stuName: null,
        awardName: null,
        awardAt: null,
        remark: null,
        awardDept: null,
        fileId: [],
      };
      this.$router.go(-1);
    },
    // 文件上传后的回调
    uploadResponse(fileIds, data) {
      if (fileIds.length) {
        this.form.fileId = fileIds;
      }
    },
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds, this)) {
            // 验证通过数据提交
            this.$dialog
              .confirm({
                title: "提示",
                message: "你确定要进行保存操作吗？",
              })
              .then(() => {
                this.loading = true;
                if (this.$route.query && this.$route.query.id) {
                  edit(this.form).then((res) => {
                    this.$notify({
                      type: "success",
                      message: "提交成功",
                      duration: 2000,
                    });
                    this.onClickLeft();
                    this.loading = false;
                  });
                } else {
                  add(this.form).then((res) => {
                    this.$notify({
                      type: "success",
                      message: "提交成功",
                      duration: 2000,
                    });
                    this.onClickLeft();
                    this.loading = false;
                  });
                }
              })
              .catch(() => {
                // on cancel
              });
          } else {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds, this);
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
  },
};
</script>

<style  lang='scss' scoped>
@import "~@/assets/styles/student/award.scss";
</style>
